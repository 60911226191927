import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";

import { withStyles } from "@mui/styles";
import themeTerms from "../themeTerms";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function AboutDescription(props) {
  const theme = themeTerms;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(1),
            display: "flex",
          }}
        >
          <Grid
            container
            spacing={5}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs="auto">
              <Typography variant="h5">The Art of Gardening</Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">
                Welcome to our gardening blog, where we share our passion for
                plants and gardening with others. Our blog is dedicated to all
                the garden lovers out there who want to learn and share their
                experiences with others. Gardening is not just a hobby but a way
                of life, where we connect with nature, learn about our
                environment, and cultivate plants that create a soothing and
                uplifting atmosphere. Our blog aims to inspire and motivate
                amateur and expert gardeners to embrace this beautiful activity
                and make the best out of it. Our team of experienced gardeners,
                horticulturists, and landscape architects are passionate about
                teaching others to maintain a beautiful garden. We believe that
                gardening is a learning process that never stops, and it is by
                sharing our journey with others that we learn and grow together.
                Our blog offers a variety of topics related to gardening, such
                as plant cultivation, garden design, landscaping, sustainable
                gardening, and much more. We provide tips and tricks to help you
                grow and maintain healthy plants, and we share inspiration and
                ideas on how to create stunning outdoor spaces that reflect your
                taste and lifestyle.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">
                We understand that gardening can be challenging, especially if
                you're just starting, and that's why we offer guidance and
                support to help you overcome any obstacles you may face. From
                choosing the right plants to dealing with pests and diseases,
                we're here to help you every step of the way. At our gardening
                blog, we encourage our readers to share their experiences, tips,
                and advice with others. We offer a community where gardeners can
                connect and learn from each other, share their successes and
                failures, and inspire one another to create beautiful gardens.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">
                We believe that gardening is a therapeutic activity that brings
                joy and fulfillment to our lives, and we hope to share this
                passion with others through our blog. Whether you're a seasoned
                gardener or just starting, we invite you to join our community
                and embark on a journey to create a beautiful and sustainable
                garden.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1">
                Join the conversation, feel free to contact us today to discuss
                everything about the Art of Gardening!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default withStyles(styles)(AboutDescription);
