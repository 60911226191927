import { Card, CardContent, CardMedia, CardHeader } from "@mui/material";
import { Typography, ThemeProvider, Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function BlogCard(props) {
  const height = props.size;
  const width = (height * 10) / 8;

  return (
    <Box sx={{ display: "flex" }}>
      <Card sx={{ width: 380, height: "30em" }}>
        <CardMedia
          component="img"
          height="194"
          image={props.data.image}
          alt="Garden"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "1em 0 0 0",
          }}
        >
          <Typography fontFamily={"Lato"} fontWeight={600} fontSize="20px">
            {props.data.title}
          </Typography>
        </Box>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.data.text}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link to={props.data.link} style={{ color: "#98ae8a" }}>
            <Typography variant="subtitle">Read more </Typography>
          </Link>
        </Box>
      </Card>
    </Box>
  );
}
