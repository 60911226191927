import React from "react";
import { ThemeProvider, Box, Typography } from "@mui/material";
import theme from "../theme";
import garden from "../../img/gardening/garden.jpg";
import composting from "../../img/gardening/composting.jpg";
import pollinating from "../../img/gardening/pollinating.jpg";
import nature from "../../img/gardening/nature.jpg";
import pumpkin from "../../img/gardening/pumpkin.jpg";
import health from "../../img/gardening/health.jpg";

import BlogCard from "../utils/Blog/BlogCard";

const blogs = [
  {
    title: "Growing",
    text: "Welcome to our comprehensive plant growing guide! This guide is designed to help you successfully grow plants of all kinds, from herbs and vegetables to flowers and shrubs. Whether you're a seasoned gardener or just getting started, our guide offers step-by-step instructions and tips to help you create a thriving garden.",
    image: garden,
    link: "/contact",
  },
  {
    title: "Composting",
    text: "Composting is an eco-friendly process that involves turning organic materials into nutrient-rich compost that can be used to improve soil quality and grow healthier plants. Composting is an easy and effective way to reduce waste and give back to the earth. With patience and a little effort, you can create nutrient-rich compost that will enhance your garden's soil and benefit the planet.",
    image: composting,
    link: "/contact",
  },
  {
    title: "Pollinating",
    text: "This flower pollinating guide is an essential resource for individuals looking to learn more about the process of flower pollination and the various pollinators that make it possible. It provides valuable information that can help individuals make informed decisions regarding the preservation of pollinators and the flowers they encourage.",
    image: pollinating,
    link: "/contact",
  },
  {
    title: "Conservation & Biodiversity",
    text: "Biodiversity is the variety of all living things on Earth, and how they fit together. It means having as wide a range of different species in an ecosystem as it can support, bringing oxygen, water, food and many other benefits.",
    image: nature,
    link: "/contact",
  },
  {
    title: "Seasonal Plants",
    text: "Seasonal plants are defined as those plants which grow and flower in a particular season. This plant gets undeveloped and prone to disease if it grows in an unsuitable season. Five important seasonal plants include peppermint, fennel, ginger, ginkgo, and chamomile.",
    image: pumpkin,
    link: "/contact",
  },
  {
    title: "Plant Health",
    text: "Plant health care is a holistic approach to tree care. The focus is on developing and maintaining healthy plants, so they are less susceptible to problems. By caring for plants and trees each season, they are often better at resisting pests and tolerating environmental stresses.",
    image: health,
    link: "/contact",
  },
];

export default function GardeningBlog(props) {
  return (
    <Box
      sx={{
        padding: "3em",
        display: "flex",
        width: "100%",
        backgroundColor: "#98ae8a",
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            marked="center"
            align="center"
            fontFamily={"Lato"}
            fontWeight={400}
            fontSize="48px"
            marginBottom={"0.5em"}
          >
            Blog Posts
            <div
              style={{
                height: 4,
                width: 60,
                background: "#F5F5F5",
                position: "absolute",
                left: "calc(50% - 30px)",
                transition: theme.transitions.create("opacity"),
                marginTop: "1%",
              }}
            />
          </Typography>
        </Box>
        <Box>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1em",
              marginTop: theme.spacing(8),
            }}
          >
            {blogs.map((blog, index) => (
              <BlogCard data={blog} key={index} size={200} />
            ))}
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
