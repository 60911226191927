import React, { useState, useEffect } from "react";
import { Button, Container, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme";

export default function ContactButton(props) {
  const [color, setColor] = useState([""]);

  const loadStatus = async () => {
    const response = await fetch("apiv2/style/");
    const data = await response.json();

    if (data.length > 0) {
      switch (data[0]["theme"]) {
        case "0":
          setColor("#0ba80b");
          break;
        case "1":
          setColor("#ffdd00");
          break;
        case "2":
          setColor("#e63333");
          break;
        default:
          setColor("#0ba80b");
      }
    } else {
      setColor("#0ba80b");
    }
  };
  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: theme.spacing(9),
          marginBottom: theme.spacing(9),
        }}
      >
        <Link to={"/contact"}>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: color,
              border: "4px solid black",
              borderRadius: 0,
              height: "auto",
              padding: theme.spacing(2, 5),
              minWidth: 200,
            }}
          >
            Contact us
          </Button>
        </Link>
      </Container>
    </ThemeProvider>
  );
}
