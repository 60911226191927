import React, { useState, useRef } from "react";

import { Button, TextField, Container } from "@mui/material";
import { Typography, Paper, Box } from "@mui/material";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { validateEmail } from "../../utils/Contact/Contact";
import { submitComment } from "../../../api/contact";

export default function Register() {
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [comment, setComment] = useState("");
  const [commentTouched, setCommentTouched] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [picture, setPicture] = useState([]);
  const [snackDisplay, setSnackDisplay] = useState("");
  const [snackType, setSnackType] = useState(""); // error, warning, info or success
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [urgent, setUrgent] = useState(false);
  const inputRef = useRef(null);

  const handleClose = () => {
    setSnackOpen(false);
  };

  const handleOpen = () => {
    setSnackOpen(true);
  };

  const emailValid = () => {
    if (validateEmail(email)) {
      return true;
    }
    return false;
  };

  const nameValid = () => {
    if (name.length >= 3) {
      return true;
    }
    return false;
  };

  const commentValid = () => {
    if (comment.length >= 3) {
      return true;
    }
    return false;
  };

  const validateForm = () => {
    if (nameValid() && emailValid() && commentValid()) {
      return true;
    }
    return false;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "");
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  var csrftoken = getCookie("csrftoken");

  function form_success() {
    setSnackDisplay("Enquiry received");
    setSnackType("success");
    setSnackOpen(true);
  }

  const uploadPicture = (e) => {
    setPicture({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      picturePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      pictureAsFile: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    let formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("comment", comment);
    formData.append("urgent", urgent);
    if (picture.length != 0) {
      formData.append("image_url", picture.pictureAsFile);
    }

    fetch("apiv2/comments/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrftoken,
      },
      body: formData,
    })
      .then((response) => {
        form_success();
      })
      .catch((error) => {
        handleErrors();
      })
      .finally(() => setButtonDisabled(false));
  };

  const handleOnImageRemoveClick = (e) => {
    setPicture([]);
    inputRef.current.value = "";
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: 0,
      }}
    >
      <Paper
        sx={{
          width: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "2em",
          marginTop: theme.spacing(9),
          marginBottom: theme.spacing(9),
          marginLeft: 0,
        }}
        elevation={5}
      >
        <Box display="flex" margin="auto" justifyContent="center" flexDirection="column" width="100%">
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Enquiries
            <div
              style={{
                height: 4,
                width: 50,
                background: "#98ae8a",
                position: "absolute",
                left: "calc(50% - 30px)",
                transition: theme.transitions.create("opacity"),
              }}
            />
          </Typography>
          <Typography variant="body1" gutterBottom marked="center" align="center">
            Have an idea? Want to discuss more about the Art of Gardening? Get in touch!
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            value={name}
            onBlur={() => setNameTouched(true)}
            onChange={(e) => setName(e.target.value)}
            error={nameTouched && !nameValid()}
            helperText={!nameValid() ? "Must be a valid name" : " "}
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email Address"
            id="email"
            value={email}
            onBlur={() => setEmailTouched(true)}
            onChange={(e) => setEmail(e.target.value)}
            error={emailTouched && !emailValid()}
            helperText={!emailValid() ? "Enter a valid email" : " "}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="comment"
            label="Comment"
            id="comment"
            value={comment}
            onBlur={() => setCommentTouched(true)}
            onChange={(e) => setComment(e.target.value)}
            error={commentTouched && !commentValid()}
            helperText={!commentValid() ? "Enter a valid comment" : " "}
          />
          {picture.picturePreview && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <img alt="not found" width={"250px"} src={picture.picturePreview} />
              </Box>
              <Box>
                <Button
                  disableElevation={true}
                  size="small"
                  type="submit"
                  variant="outlined"
                  disabled={!picture.picturePreview || buttonDisabled}
                  sx={{
                    height: "25px",
                    margin: theme.spacing(3, 0, 3),
                    color: "black",
                    borderColor: "#767676",

                    backgroundColor: "#F0F0F0",
                    ":hover": {
                      backgroundColor: "#D9D9D9",
                      color: "black",
                      borderColor: "#767676",
                    },
                  }}
                  onClick={handleOnImageRemoveClick}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              paddingTop: "1em",
            }}
          >
            <input ref={inputRef} type="file" name="image" onChange={uploadPicture} />
          </Box>
          <Box
            sx={{
              display: "flex",
              paddingTop: "1em",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox style={{ color: "#98ae8a" }} checked={urgent} onChange={(e) => setUrgent(e.target.checked)} />
              }
              label={
                <Typography variant="body2" color="textSecondary">
                  Is your enquiry urgent?
                </Typography>
              }
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!validateForm() || buttonDisabled}
            sx={{
              margin: theme.spacing(3, 0, 3),
              backgroundColor: "#98ae8a",
              ":hover": {
                bgcolor: "#98AE8A90",
                color: "white",
              },
            }}
            onClick={handleSubmit}
          >
            SEND ENQUIRY
          </Button>
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackType}>
          {snackDisplay}
        </Alert>
      </Snackbar>
    </Container>
  );
}
