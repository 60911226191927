import React from "react";
import { Container, ThemeProvider, Box } from "@mui/material";
import theme from "../../theme";
import "./LandingPage.css";
import logo from "../../../img/logo/landingpagelogo2.png";

export default function LandingPage(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          color: theme.palette.common.white,
          position: "relative",
          display: "flex",
          alignItems: "center",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            height: "70vh",
            minHeight: 500,
            maxHeight: 1300,
            width: "100%",
          },
        }}
      >
        <Container
          sx={{
            display: "flex",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(14),
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="bg"></div>
          <div id="logo">
            <img alt="AOG logo" src={logo} width="478" height="493" />
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
