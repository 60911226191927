import { Card, CardMedia } from "@mui/material";
import { Typography, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";

import theme from "../theme";

export default function MediaCard(props) {
  const height = props.size;
  const width = (height * 10) / 8;

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ position: "relative" }}>
        <Link to={props.data.link} style={{ color: "#FDB813" }}>
          <CardMedia
            component="img"
            sx={{
              height: `${height}px`,
              width: `${width}px`,
            }}
            src={props.data.url}
            title="Category"
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "80px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          >
            <Typography fontFamily={"Lato"} fontWeight={600} fontSize="20px">
              {props.data.title}
            </Typography>
          </div>
        </Link>
      </Card>
    </ThemeProvider>
  );
}
