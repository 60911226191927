import React from "react";
import { Container, ThemeProvider, Typography, Box } from "@mui/material";
import theme from "../theme";

export default function AppSubtitle(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#98ae8a",
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            fontFamily={"Lato"}
            fontWeight={300}
            fontSize="20px"
          >
            Taking gardening to the next level.
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
